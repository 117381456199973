<template>
  <div class="flex flex-col gap-2">
    <div v-if="title" class="text-secondary-900 text-xs">{{ title }}</div>
    <BaseDropdown full-width placement="bottom" :fixed="fixed" :gap="0">
      <template #trigger="{ triggered }">
        <div
          class="flex w-full cursor-pointer gap-2 relative items-center justify-between border border-secondary-300 rounded-[4px] px-4 min-h-[38px]">
          <div class="truncate">
            {{
              currentValue
                ? `${props.optionPrefix} ${currentValue}`
                : defaultValue
            }}
          </div>
          <ChevronDown :class="{ 'rotate-180	': triggered }" />
        </div>
      </template>
      <div
        v-if="options.length"
        class="bg-white border overflow-y-auto max-h-[30vh]">
        <div
          v-if="currentValue && defaultValue"
          class="px-4 last:border-0 flex flex-col border-b py-3 truncate hover:bg-secondary-400 cursor-pointer"
          @click="() => onSelect(null)">
          {{ defaultValue }}
        </div>
        <div
          v-for="(option, index) in availableOptions"
          :key="index"
          class="px-4 last:border-0 flex flex-col border-b py-3 truncate hover:bg-secondary-400 cursor-pointer"
          @click="() => onSelect(option)">
          {{ `${props.optionPrefix} ${option}` }}
        </div>
      </div>
    </BaseDropdown>
  </div>
</template>

<script setup lang="ts">
import { ChevronDown } from 'lucide-vue-next';
import { computed, ref } from 'vue';
import { BaseDropdown } from '@nwire/amit-design-system';

interface IProps {
  options: string[];
  currentValue: any;
  defaultValue?: string;
  title?: string;
  optionPrefix?: string;
  fixed?: boolean;
}
const props = withDefaults(defineProps<IProps>(), {
  options: () => [],
  currentValue: '',
  title: '',
  defaultValue: '',
  optionPrefix: '',
  fixed: true,
});
const selected = ref(props.currentValue);
const emit = defineEmits(['select']);
const availableOptions = computed(() =>
  props.options.filter(item => item !== selected.value),
);

const onSelect = option => {
  emit('select', option);
  selected.value = option;
};
</script>
